<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2681_24445)">
      <path
        d="M0.999512 12.001C0.999512 5.92098 5.91951 1.00098 11.9995 1.00098C15.7195 1.00098 19.0095 2.85098 20.9995 5.67098V3.00098H22.9995V9.00098H16.9995V7.00098H19.4795C17.8695 4.59098 15.1195 3.00098 11.9995 3.00098C9.61256 3.00098 7.32338 3.94919 5.63555 5.63701C3.94772 7.32484 2.99951 9.61403 2.99951 12.001H0.999512Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.03227 13.1468C0.997983 12.7691 0.980469 12.3865 0.980469 12H3.02047C3.02047 12.3249 3.03519 12.6459 3.06392 12.9623L1.03227 13.1468ZM2.27931 17.5498C1.93978 16.8703 1.66073 16.1528 1.44948 15.4053L3.4126 14.8505C3.58889 15.4743 3.82154 16.0723 4.10421 16.6381L2.27931 17.5498ZM5.07249 21.1883C4.49648 20.6808 3.96935 20.1159 3.49904 19.5023L5.11819 18.2613C5.5086 18.7707 5.94523 19.2384 6.42098 19.6575L5.07249 21.1883ZM18.5532 22.2293C18.1067 22.5174 17.6396 22.7749 17.1545 22.9986L16.3001 21.1461C16.697 20.9631 17.08 20.7521 17.447 20.5152L18.5532 22.2293ZM9.06981 23.4778C8.32803 23.2367 7.61905 22.9197 6.95117 22.5361L7.96734 20.7672C8.51524 21.0819 9.0952 21.341 9.7004 21.5377L9.06981 23.4778ZM14.0953 23.9056C13.5736 23.9811 13.0411 24.02 12.5005 24.02C12.1093 24.02 11.7224 23.9996 11.3407 23.9598L11.5525 21.9308C11.864 21.9633 12.1802 21.98 12.5005 21.98C12.943 21.98 13.3779 21.9481 13.8034 21.8866L14.0953 23.9056Z"
        fill="currentColor"
      />
      <path
        d="M9.98852 9.96C9.98852 10.8463 10.7824 11.1951 12.4254 11.7514C14.6408 12.534 15.2039 13.6843 15.2039 14.9949C15.2039 17.4746 12.8962 17.946 12.527 18.0309V19.2H10.6808V17.9837C10.1085 17.814 8.58544 17.2577 8.00391 15.1929L9.53621 14.5611C9.5916 14.7686 10.0716 16.5317 11.7516 16.5317C12.6101 16.5317 13.5793 16.0791 13.5793 15.0137C13.5793 14.1086 12.9331 13.6277 11.4747 13.0997C10.4593 12.7226 8.38237 12.1191 8.38237 9.97886C8.38237 9.88457 8.3916 7.716 10.6808 7.188V6H12.527V7.16914C14.1147 7.47086 14.7331 8.85686 14.8716 9.28114L13.4131 9.91286C13.3116 9.58286 12.8685 8.64943 11.6593 8.64943C11.0131 8.64943 9.98852 8.99829 9.98852 9.96Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2681_24445">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IcTargetRoi',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped></style>
